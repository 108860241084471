module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eutact Corp.","short_name":"Eutact","start_url":"/","background_color":"#0A0A36","theme_color":"#A61DEA","display":"minimal-ui","icon":"src/images/eutact-logo-brief.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"12741345002d0330786e68b803bab522"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
