// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-schedule-a-call-js": () => import("./../../src/pages/schedule-a-call.js" /* webpackChunkName: "component---src-pages-schedule-a-call-js" */),
  "component---src-pages-style-guide-js": () => import("./../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */)
}

