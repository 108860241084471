import { darken } from "@theme-ui/color"

export const theme = {
  // Breakpoints
  breakpoints: ["600px", "960px", "1280px", "1920px"],
  // Spacing
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // Typography
  fonts: {
    primary: "Manrope, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 18, 22, 30, 44, 60, 92, 200],
  fontWeights: {
    regular: 400,
    medium: 600,
    bold: 800,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.125,
  },
  // Colors
  colors: {
    // default
    text: "#262626",
    background: "#FFFFFF",
    primary: "#A61DEA",
    secondary: "#252568",
    highlight: "#F4E9FA",
    muted: "#E1A4FF",
    // custom
    textHighlight: "#FFFFFF",
    dark: "#0A0A36",
  },
  // Variants
  text: {
    body: {
      fontFamily: "primary",
      lineHeight: "body",
      fontWeight: "regular",
      fontSize: 3,
      color: "text",
    },
    ghost: {
      fontFamily: "primary",
      lineHeight: "heading",
      fontWeight: "bold",
      fontSize: 9,
    },
    section_headline: {
      fontFamily: "primary",
      lineHeight: "heading",
      fontWeight: "bold",
      fontSize: 6,
    },
    section_crosshead: {
      fontFamily: "primary",
      lineHeight: "heading",
      fontWeight: "medium",
      fontSize: [5, 6, 6, null],
    },
    button: {
      fontFamily: "primary",
      lineHeight: "body",
      fontWeight: "medium",
      fontSize: 3,
      color: "textHighlight",
      textDecoration: "none",
    },
    imprint: {
      fontFamily: "primary",
      lineHeight: "body",
      fontWeight: "medium",
      fontSize: 0,
    },
  },
  links: {
    nav: {
      color: "textHighlight",
      fontFamily: "primary",
      lineHeight: "body",
      fontWeight: "bold",
      fontSize: 2,
      textDecoration: "none",
      textTransform: "uppercase",
      mx: 2,
      py: 2,
    },
  },
  button: {
    "cursor": "pointer",
    "&:hover": {
      cursor: "pointer",
      background: (t) => `${darken("primary", 0.1)(t)}`,
    },
  },
  // layout
  layout: {
    container: {
      mx: "auto",
      maxWidth: "1024px",
      padding: 4,
    },
  },
  // styles
  styles: {
    root: {
      variant: "text.body",
    },
    h1: {
      variant: "text.ghost",
    },
    h2: {
      variant: "text.section_headline",
    },
    h3: {
      variant: "text.section_crosshead",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}

export default theme
